<template>
  <div id="error-layout">
    <Header />
    <div class="error-wrapper">
      <div class="error-thumbnail">
        <img :src="this.errorImg" alt="" />
      </div>
      <div class="error-info">
        <h2 class="error-title">{{ this.errorMessage }}</h2>
        <p class="error-desc" v-if="this.errorDesc"> {{ this.errorDesc }} </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "./styles.scss";
</style>

<script>
import {
  HTTP_CODE_INTERNAL_SERVER_ERROR,
  HTTP_CODE_NOT_FOUND,
} from "@constants";
import { IMAGES } from "@assets";
import { Header } from "@components";

export default {
  name: "ErrorLayout",
  data() {
    return {
      IMAGES,
      errorImg: null,
      errorMessage: null,
      errorDesc: null,
    };
  },
  components: {
    Header,
  },
  props: {
    errorStatus: {
      type: Number,
      required: true,
    },
  },
  methods: {
    setError: function () {
      switch (this.errorStatus) {
        case HTTP_CODE_NOT_FOUND:
          this.errorImg = IMAGES.error_404;
          this.errorMessage = this.$t("messages.page_not_found");
          this.errorDesc = "You can exchange Gift Points to eGift in \"Gifts\".";
          break;
        case HTTP_CODE_INTERNAL_SERVER_ERROR:
          this.errorImg = IMAGES.error_500;
          this.errorMessage = "Something is wrong.";
          break;
      }
    },
  },
  mounted() {
      this.setError();
  }
};
</script>
